import React from "react"

import { graphql } from "gatsby"
import { Box, Heading, Text } from "grommet"

const NotFoundPage = ({
  data: {
    strapiNotFound: { title, sub_heading },
  },
}) => {
  return (
    <Box width="large" align="center" justify="center" fill flex="grow">
      <Heading level={1}>{title}</Heading>
      <Text size={"large"}>{sub_heading}</Text>
    </Box>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    strapiNotFound {
      id
      title
      sub_heading
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
